import { clsx } from 'clsx';
import Partials from 'components/partials/Partials';
import type { PartialWidgetContext } from 'types/cms';
import type { PropsWithClassName } from 'types/react-props';
import styles from './group-grid-2-1.module.scss';

export type GroupGrid21Props = PropsWithClassName<{
  readonly children: ReadonlyArray<PartialWidgetContext>;
  readonly model?: {
    readonly fields?: {
      readonly isSecondColumnSticky?: boolean;
    };
  };
}>;

const GroupGrid21 = ({ context }: { context: GroupGrid21Props }) => (
  <div className={clsx(styles.grid, context.className)}>
    {context.children.map((widget: PartialWidgetContext, index: number) => {
      if (context.model?.fields?.isSecondColumnSticky && index === 1) {
        return (
          <div key={widget.id} className={styles.stickyColumn}>
            <Partials context={widget} />
          </div>
        );
      }
      return <Partials key={widget.id} context={widget} />;
    })}
  </div>
);

export default GroupGrid21;
